.builder-contacts {
  padding: 0 20px 24px;
}

.builder-logo {
  margin-right: 10px;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
}

.builder-info {
  margin-bottom: 10px;
}

.builder-name {
  margin: 10px 0 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  vertical-align: top;
  color: #121212;
}

.call-button {
  padding: 8px 0;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  color: var(--accent-main-primary);
  border: 1px solid var(--accent-main-primary);
  border-radius: 45px;
}

.action-container {
  margin-top: 20px;
}

.close-button {
  margin: 0 auto;
  padding: 4px 15px;
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: var(--accent-main-primary);
  background-color: transparent;
  border: 0;
}
