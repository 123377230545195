.button {
  padding: 16px 0;
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #2b87db;
  border: 0;
  border-radius: 100px;
}
