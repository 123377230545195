.container {
  position: fixed;
  position: sticky;
  z-index: 10;
  left: 0;
  bottom: 16px;
  margin: 20px 10px;
}

@media (min-width: 641px) {
  .container {
    margin: 20px 60px;
  }
}
